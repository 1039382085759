import React, { useEffect, useState } from "react";
import {
  IconAngleArrowRight,
  IconAngleArrowTop,
  IconCheck,
  IconDual,
  IconTimes,
  IconWarning,
} from "Common/Components/Icons/Icons";
import { DataTypesI, EmptiesTypes, LaPropsI } from "../location";
import sslFile from "../sslFile.json";

const LAEmptyTable = (props: LaPropsI) => {
  const { data, emptyData, shift, portsArray, containerTypes, getEmptyAppointmentSlots } = props;

  // ---------------------------------------------------- STATE MANAGEMENT---------------------------------------------------//
  const [emptyReturnsInfo, setEmptyReturnsInfo] = useState<string[]>(sslFile.shipping_lines);

  // ---------------------------------------------------- HANDLE FUNCTIONS ---------------------------------------------------//
  const showEmptyReturnsInfo = (ssl: string) => {
    if (emptyReturnsInfo.includes(ssl)) {
      const temp = emptyReturnsInfo.filter((fil) => fil !== ssl);
      setEmptyReturnsInfo([...temp]);
    } else {
      const temp = emptyReturnsInfo;
      temp.push(ssl);
      setEmptyReturnsInfo([...temp]);
    }
  };
  const checkStatus = (str: any) => {
    const value = str.toUpperCase();
    if (value.includes("NO") || value.includes("CLOSED") || value.includes("RESTRICTED")) {
      return "NO";
    } else if (value.includes("DUAL") && value.includes("YES")) {
      return "YES";
    } else if (value.includes("DUAL")) {
      return "DUAL";
    } else {
      return "YES";
    }
  };

  const filterEmptyReturn = (empty: any, types: any) => {
    let sslData: any;
    const find = emptyData?.find((fil: EmptiesTypes) => fil?.key === empty?.label);
    sslData = find?.data?.data?.find(
      (ss: DataTypesI) => ss.ssl === data && ss.types === types.label
    );

    return sslData;
  };

  const EmptiesIcon = (text: string) => {
    switch (text.trim()) {
      case "OPEN":
        return "success";
      case "RESTRICTED":
        return "danger";
      case "CONTACT SSL":
        return "secondary";
      case "DUAL":
        return "warning";
      default:
        break;
    }
  };

  const sslShiftData = (shiftType: string, sslData: any) => {
    switch (shiftType) {
      case "shift1":
        return sslData.shift1;
      case "shift2":
        return sslData.shift2;
      case "shift3":
        return sslData.shift3;
      default:
        break;
    }
  };
  const sslShiftRemark = (shiftType: string, sslData: any) => {
    switch (shiftType) {
      case "shift1":
        return sslData.remarks || sslData.remarks1;
      case "shift2":
        return sslData.remarks2;
      case "shift3":
        return sslData.remarks3;
      default:
        break;
    }
  };
  return (
    <>
      <tbody>
        <tr>
          <td colSpan={100}>
            <div className="d-flex align-items-center">
              <span className="font-14 text-dark font-weight-500">{data}</span>
              <button className="btn btn-link" onClick={() => showEmptyReturnsInfo(data)}>
                {emptyReturnsInfo.includes(data) ? <IconAngleArrowTop /> : <IconAngleArrowRight />}
              </button>
            </div>
          </td>
        </tr>
        {emptyReturnsInfo.includes(data) && (
          <>
            {containerTypes.map((types: any, index: number) => {
              return (
                <tr key={index}>
                  {types.checked && (
                    <React.Fragment>
                      <td width="100"> {types.label} </td>
                      {portsArray.map((pp, pIndex) => {
                        if (pp.checked) {
                          // const find = emptyData?.find(
                          //   (fil: EmptiesTypes) => fil?.key === pp.label
                          // );
                          // const sslData: any = find?.data?.data?.find(
                          //   (ss: DataTypesI) => ss.ssl === data && ss.types === types.label
                          // );
                          const sslData = filterEmptyReturn(pp, types);
                          const totalAvailableAppointmentSlots =
                            sslData &&
                            ["OPEN", "DUAL"].includes(sslData[shift]) &&
                            getEmptyAppointmentSlots
                              ? getEmptyAppointmentSlots(sslData)
                              : null;

                          return sslData && sslData[shift] ? (
                            <td key={pIndex} className="text-center">
                              <div className="d-flex flex-column align-items-center justify-content-center">
                                <span
                                  className={`text-${EmptiesIcon(sslShiftData(shift, sslData))}`}
                                >
                                  {sslShiftData(shift, sslData)}
                                </span>
                                {["OPEN", "DUAL"].includes(sslData[shift]) &&
                                totalAvailableAppointmentSlots ? (
                                  <span className="text-center text-secondary">
                                    {totalAvailableAppointmentSlots}
                                  </span>
                                ) : null}
                                {/* {checkStatus(sslData[shift]) === "DUAL" ? (
                                  <IconDual className="text-primary wh-10px text-warning" />
                                ) : checkStatus(sslData[shift]) === "YES" ? (
                                  <IconCheck className="text-primary wh-20px" />
                                ) : (
                                  <IconTimes className="text-error-500 wh-20px" />
                                )} */}

                                {sslShiftRemark(shift, sslData)?.length > 1 ? (
                                  <div className="position-relative tooltip-label">
                                    <IconWarning className="text-primary wh-10px text-warning ml-1" />

                                    <div
                                      className="tooltip tooltip--gray-700 mw-200"
                                      data-placement="top-center"
                                    >
                                      {sslShiftRemark(shift, sslData)}
                                      <span className="arrow"></span>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </td>
                          ) : (
                            <td key={pIndex} className="text-center"></td>
                          );
                        }
                      })}
                    </React.Fragment>
                  )}
                </tr>
              );
            })}
          </>
        )}
      </tbody>
    </>
  );
};
export default LAEmptyTable;

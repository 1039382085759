import { Fragment, useEffect, useState } from "react";
import moment from "moment";
import { useAppContext } from "contexts/AppContext";
import { handleSort } from "pages/EmptyReturn/Common/utils";
import { tableHeaders } from "../constant";
import { addNewSession, setContainerCredentialsParams, testContainerCredentials } from "../action";
import EditCredentialModal from "./EditCredentialModal";
import Toastr from "Common/Components/Toastr";
import { IconCancelCircle } from "Common/Icons";
import { IconClone } from "Common/Icons";
import Loader from "Common/Components/Loader";

const CredentialsTable = () => {
  const { state, myDispatch } = useAppContext();
  const { containerCredentials, searchParams, searchPost, sLoading } = state.supportStates;
  const defaultNotification = {
    id: "",
    isLoading: false,
    success: false,
    message: "",
    status: "",
    type: "",
  };
  //Copy from Session Column
  // const [copySessionColumn, setCopySessionColumn] = useState();

  // const handleCopySession
  const [notification, setNotification] = useState<any | null>(defaultNotification);
  const [more, setMore] = useState<any | null>();
  const [selectedCredential, setSelectedCredential] = useState<any | null>();
  const onSort = (obj: any) => {
    const searchParamsD = handleSort(obj, tableHeaders, searchParams);
    myDispatch(setContainerCredentialsParams(searchPost, searchParamsD));
  };
  const handleAutoFix = async (oldCredential: Record<string, string>) => {
    setNotification({ ...notification, isLoading: true, type: "auto", id: oldCredential._id });
    try {
      const response = addNewSession({
        userId: oldCredential.userId,
        portId: oldCredential.portId,
      });
      myDispatch(response);
      const { data } = await response.payload;
      const success = data.operationVals.success;
      const message = data.operationVals.msg;
      if (success) {
        const updatedCredential = {
          ...oldCredential,
          updatedAt: data.data.updatedAt,
        };
        const updatedCredentials = containerCredentials.map((credential: any) =>
          credential._id === oldCredential._id ? updatedCredential : credential
        );
        myDispatch(
          setContainerCredentialsParams(searchPost, { ...searchParams, updatedCredentials })
        );
        setNotification({ ...notification, success: true, message, status: "success" });
      } else {
        setNotification({ ...notification, success: false, message, status: "error" });
      }
    } catch (error) {
      console.error("Error in AutoFix:", error);
      setNotification({
        ...notification,
        success: false,
        message: "An error occurred while updating credentials.",
        status: "error",
      });
    }
  };

  const resetNotification = () => {
    setNotification(defaultNotification);
  };
  const handleTest = async (credential: any) => {
    setNotification({ ...notification, isLoading: true, type: "test", id: credential._id });

    const requestBody = {
      portUsername: credential.portUsername,
      portPassword: credential.portPassword,
      port: credential.port,
    };
    const { payload } = await testContainerCredentials(requestBody);
    const { data } = payload;
    if (data.data.success) {
      setNotification({
        ...notification,
        success: true,
        message: data.data.message,
        status: "success",
      });
    } else {
      setNotification({
        id: Math.random(),
        success: true,
        message: data.data.message,
        status: "error",
      });
    }
  };

  return (
    <Fragment>
      {notification.success && (
        <Toastr
          key={notification.id}
          show={notification.success}
          status={notification.status}
          message={notification.message}
          reset={resetNotification}
        />
      )}
      {sLoading && <Loader />}
      <div className="table-responsive" style={{ height: "calc(100vh - 255px)" }}>
        <table className="table table-card">
          <thead>
            <tr>
              <th>Sno</th>
              {tableHeaders.map((item: any, i: number) => {
                let sortIcon = "table-sort table-sort--default";
                if (item.sort === "-" && item.active) {
                  sortIcon = "table-sort table-sort--ascending";
                }
                if (item.sort === "+" && item.active) {
                  sortIcon = "table-sort table-sort--descending";
                }
                return (
                  <th
                    key={i}
                    onClick={() => onSort(item)}
                    className={
                      item.sort
                        ? item.label === "Session Update"
                          ? `text-center ${sortIcon}`
                          : sortIcon
                        : ""
                    }
                  >
                    {item.label}
                  </th>
                );
              })}
            </tr>
          </thead>

          <tbody>
            {containerCredentials ? (
              containerCredentials?.map((credential: any, i: number) => {
                let sessions =
                  credential.port === "MAHER TERMINAL"
                    ? credential?.sessions?.token
                    : credential.sessions;
                return (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{credential.userId}</td>
                    <td>{credential.port}</td>
                    <td>{credential.portUsername}</td>
                    <td>{credential.portPassword}</td>
                    <td>
                      <div
                        style={{
                          maxWidth: 200,
                          whiteSpace: "break-spaces",
                          lineBreak: "anywhere",
                        }}
                      >
                        {sessions && typeof sessions === "string" && (
                          <span onClick={() => setMore(i === more ? null : i)}>
                            {sessions && more === i && sessions}
                            {sessions && more !== i && sessions.substring(0, 50) + "..."}
                          </span>
                        )}
                      </div>
                    </td>
                    <td>
                      {credential.port === "EVERPORT TERMINAL" ? credential?.meta?.skValue : "-"}
                    </td>
                    <td className={credential?.auth_failed ? "bg-danger" : ""}>
                      {credential?.auth_failed?.toString()?.toUpperCase()}
                    </td>
                    <td className={credential?.session_failed ? "bg-danger" : ""}>
                      {credential?.session_failed?.toString()?.toUpperCase()}
                    </td>
                    <td>
                      {credential.updatedAt ? moment(credential.updatedAt).format("lll") : ""}
                    </td>
                    <td className="text-center">
                      <button
                        className="btn btn-primary mr-10"
                        onClick={() => handleAutoFix(credential)}
                      >
                        {notification.isLoading &&
                          notification.id === credential._id &&
                          notification.type === "auto" && (
                            <span
                              className="spinner-border spinner-border-sm mr-2"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )}
                        Auto
                      </button>
                      <button
                        className={
                          credential.port === "MAHER TERMINAL"
                            ? "btn btn-disabled"
                            : "btn btn-secondary"
                        }
                        onClick={() => {
                          setSelectedCredential(credential);
                          console.log("clicked");
                        }}
                      >
                        Manual
                      </button>
                    </td>
                    <td>
                      <button
                        className="btn btn-success mr-10"
                        onClick={() => handleTest(credential)}
                      >
                        {notification.isLoading &&
                          notification.id === credential._id &&
                          notification.type === "test" && (
                            <span
                              className="spinner-border spinner-border-sm mr-2"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )}
                        Test
                      </button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={100}>Data Not Found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {/* Container Details Modal */}
      {selectedCredential && selectedCredential.port && (
        <EditCredentialModal
          credentials={selectedCredential}
          onCancel={() => setSelectedCredential(null)}
          sLoading={sLoading}
        />
      )}
    </Fragment>
  );
};

export default CredentialsTable;
